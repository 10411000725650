import {IStory} from "./models";
import React, {useCallback, useEffect, useState} from "react";
import {apiBack, apiGetRead, apiMarkRead} from "./apis";
import Stories from "./Stories";
import MyButton from "../ui/MyButton";
import Row from "react-bootstrap/Row";
import { useSearchParams } from "react-router-dom";

function Reader() {
    const [searchParams] = useSearchParams();

    const [stories, setStories] = useState<IStory[]>();

    const reloadReader = useCallback(() => {
        const limitString = searchParams?.get('limit');
        const limit = limitString == null ? undefined : +limitString;

        const site = searchParams?.get('site') ?? undefined;

        apiGetRead(limit, site)
            .then(setStories)
            .then(() => window.scrollTo(0, 0))
    }, [searchParams]);

    useEffect(reloadReader, [reloadReader]);

    const back = () => apiBack().then(reloadReader);

    const markAllRead = (liked: boolean) => {
        if (stories && stories.length) {
            apiMarkRead(
                stories.map(story => ({storyId: story.storyId, liked}))
            ).then(reloadReader);
        }
    }

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            switch (event.code) {
                case 'ArrowLeft':
                    back();
                    break;
                case 'ArrowRight':
                    markAllRead(false);
                    break;
                case 'KeyL':
                    markAllRead(true);
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    });

    return <>
        <Stories stories={stories} reloadReader={reloadReader} showReadButtons={true}/>
        <Row>
            <MyButton text="Back" offset={8} onClick={back}/>
            <MyButton text="All Read" onClick={() => markAllRead(false)}/>
        </Row>
    </>;
}

export default Reader;
